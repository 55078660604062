<template>
    <div>
        <!-- Панель управления -->
        <div class="filter-container">
            <!-- Хлебная крошка -->
            <div v-if="currentMode !== 'add'" class="filter-breadcrumb">
                <span class="item-breadcrumb">
                {{ mainAgreement.numberContract }}
                </span>
                <span class="item-breadcrumb" @click="openAdditionalsModal('open')">
                    <template v-if="chosenAgreement.type === 0">
                        Основной
                    </template>
                    <template v-else>
                        {{ chosenAgreement.numberContract }}
                    </template>
                </span>    
            </div>
            <div style="display: flex;">
                <b-button @click="goBack">
                    К реестру
                </b-button>
                <div style="margin-left: 15px;">
                    <b-button variant="light" v-b-modal.show-data><i class="icon icon-duplicate"></i>отобразить данные</b-button>
                    <b-button v-if="isWriteAccess" @click="openSaveModal" variant="primary">
                            <i class="icon icon-plus-circle"></i>
                            <span class="left">Добавить платеж</span>
                    </b-button>
                </div>
            </div>
        </div>
        <div class="inner-container">
            <!-- Шапка -->
            <div class="form-card">
                <b-row>
                    <b-col cols="12" md="6">
                        <b-form-group label="Договор">
                            <b-form-input :value="headerData.mainNumberContract" type="text" :disabled="true" />
                        </b-form-group>
                        <b-form-group label="Доп. соглашение">
                            <b-form-input :value="headerData.actualNumberContract" type="text" :disabled="true" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group label="Сумма договора">
                            <b-form-input :value="formatDigitReadability(headerData.actualAmount)" type="text" :disabled="true" />
                        </b-form-group>
                        <b-form-group label="Заемщик">
                            <b-form-input :value="headerData.actualContractor" :disabled="true" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <b-progress variant="success" v-show="progress < 100" height="10px" :value="progress" striped animated />
            <!-- Таблица -->
            <div class="table-container b-table-sticky-header">
                <b-table
                    :fields="tableFields"
                    :items="scheduleData"
                    responsive="true"
                    bordered
                    head-variant="light"
                    no-border-collapse
                    sticky-header="true"
                >
                    <template #cell(rowNumber)="data">
                        {{ data.index + 1 }}
                    </template>

                    <template #cell(planDatePayment)="data">
                        <div class="text-right nowrap">
                            {{ millisToDate(data.value) }}
                        </div>
                    </template>
                    <template #cell(datePayment)="data">
                        <div class="text-right nowrap">
                            {{ millisToDate(data.value) }}
                        </div>
                    </template>
                    <template #cell(dateBeg)="data">
                        <div class="text-right nowrap">
                            {{ millisToDate(data.value) }}
                        </div>
                    </template>
                    <template #cell(dateEnd)="data">
                        <div class="text-right nowrap">
                            {{ millisToDate(data.value) }}
                        </div>
                    </template>

                    <template #cell(leftPrincipal)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                        </div>
                    </template>
                    <template #cell(planRepaymentSum)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                        </div>
                    </template>
                    <template #cell(factIncomeSum)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(data.value) }}
                        </div>
                    </template>
                    <template #cell(factRepaymentSum)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(data.value) }}
                        </div>
                    </template>
                    <template #cell(sumBase)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                        </div>
                    </template>
                    <template #cell(planInterestRepaymentSum)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                        </div>
                    </template>
                    <template #cell(interestPaymentSum)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                        </div>
                    </template>
                    <template #cell(followingPaymentsSum)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(data.value) }}
                        </div>
                    </template>
                    <template #cell(forecastCopaymentSum)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                        </div>
                    </template>
                    <template #cell(earlyRepaymentSum)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(data.value) }}
                        </div>
                    </template>
                    <template #cell(unusedSum)="data">
                        <div class="text-right nowrap">
                            {{ formatDigitReadability(data.value) }}
                        </div>
                    </template>
                    <template #cell(earlyRepaymentFlag)="data">
                        <div class="text-right nowrap">
                            <template v-if="!data.item.code"></template>
                            <template v-else-if="data.item.code && data.item.code.substring(0, 4) !== '5011'"></template>
                            <template v-else>
                                <div @click="confirmEarlyRepaymentFlagToggle(data.item, data.value)">
                                    <b-form-checkbox
                                        v-if="data.value"
                                        :checked="true"
                                        :disabled="true"
                                    />
                                    <b-form-checkbox
                                        v-if="!data.value"
                                        :checked="false"
                                        :disabled="true"
                                    />
                                </div>
                            </template>
                        </div>
                    </template>

                    <template #cell()="data">
                        <div class="text-right">{{ data.value }}</div>
                    </template>

                    <template v-if="isWriteAccess" #cell(action)="data">
                        <div v-if="data.item.isFact">
                            <b-dropdown dropleft class="more-dropdown">
                                <template v-slot:button-content>
                                    <i class="icon icon-more"></i>
                                </template>
                                <template>
                                    <b-dropdown-item-btn @click="confirmDeletion(data.item.id)">
                                        <span style="color: red">Удалить</span>
                                    </b-dropdown-item-btn>
                                    <b-dropdown-item-btn @click="openEditModal(data.item)">
                                        Редактировать
                                    </b-dropdown-item-btn>
                                </template>
                            </b-dropdown>
                        </div>
                    </template>

                    <!-- Строка "Итого" -->
                    <template v-if="scheduleData.length > 0" #bottom-row="data">
                        <td class="sticky-bottom-row"></td>
                        <td class="text-left bold sticky-bottom-row" colspan="5">
                            <div style="display: flex; align-items: center;">
                                <div>ИТОГО</div>
                            </div>
                        </td>
                        <td class="text-right bold sticky-bottom-row">{{ formatDigitReadability(formatMaxFloat(rounding(totalInflow))) }}</td>
                        <td class="text-right bold sticky-bottom-row">{{ formatDigitReadability(formatMaxFloat(rounding(totalRepayment))) }}</td>
                        <td class="text-right bold sticky-bottom-row">{{ formatDigitReadability(formatMaxFloat(rounding(totalRefund))) }}</td>
                        <td class="text-right bold sticky-bottom-row">{{ formatDigitReadability(formatMaxFloat(rounding(calculateTotalUnusedSum()))) }}</td>
                        <td class="text-right bold sticky-bottom-row">{{ formatDigitReadability(formatMaxFloat(rounding(calculateTotalPlanInterest()))) }}</td>
                        <td class="text-right bold sticky-bottom-row">{{ formatDigitReadability(formatMaxFloat(rounding(totalInterest))) }}</td>
                        <td class="text-right bold sticky-bottom-row">{{ formatDigitReadability(formatMaxFloat(rounding(calculateTotalForecastCopayment()))) }}</td>
                        <td class="text-right bold sticky-bottom-row">{{ formatDigitReadability(formatMaxFloat(rounding(totalCoopayment))) }}</td>
                        <td class="text-right bold sticky-bottom-row" colspan="3"></td>
                    </template>
                </b-table>
            </div>
        </div>

        <!-- Модалка для показа данных -->
        <b-modal id="show-data"
                 size="md"
                 hide-footer
                 centered
                 modal-class="multiselect-modal multiselect-add-modal"
        >
            <div class="modal-text">
                Платеж по договору {{ chosenAgreement.numberContract }}
                от {{ chosenAgreement.dateContract }}
                по проекту {{ chosenAgreement.codeProject.nameRu }}
            </div>
            <div class="d-flex justify-content-center mt-3">
                <b-button variant="light" @click="copyData">Скопировать</b-button>
            </div>
        </b-modal>

        <b-modal
            id="add-payment"
            v-model="showModal"
            size="lg"
            hide-footer
            centered
            modal-class="multiselect-modal multiselect-add-modal"
        >
            <template #modal-header="{ close }">
                <div class="title">Добавить платеж</div>
                <i class="icon icon-close" @click="close()"></i>
            </template>
                <b-form-radio-group v-model="selected" @change="onModalModeChange" class="modal-radio-fact-graphic">
                    <b-form-radio :value="1">создать платеж</b-form-radio>
                    <b-form-radio :value="2">данные формы 2-43</b-form-radio>
                </b-form-radio-group>
            <div class="modal-fact-graphic-content">
                <b-form-group label="Наименование платежа" v-if="selected === 2">
                    <multiselect
                        v-model="item243"
                        :options="options243"
                        label="label"
                        track-by="id"
                        @input="on243Select"
                    />
                </b-form-group>
                <b-form-group label="КБК">
                    <multiselect
                        v-model="newPaymentForm.kbk"
                        :options="kbkOptions"
                        label="label"
                        track-by="id"
                        @input="onKbkSelect"
                        :disabled="selected == 2"
                    />
                </b-form-group>
                <b-form-group label="Вид платежа">
                    <b-form-input
                        type="text"
                        :value="displayTypeName(newPaymentForm.paymentType)"
                        :disabled="true"
                    />
                </b-form-group>
                <b-row>
                    <b-col cols="12" md="6">
                        <b-form-group label="Дата">
                            <b-form-input
                                type="date"
                                :value="millisToDate(newPaymentForm.date)" 
                                :formatter="handleDateFormat"
                                @input="value => handleNewPaymentFormInput('date', value)"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group label="Сумма">
                            <b-form-input
                                type="text"
                                :value="newPaymentForm.amount"
                                :formatter="formatAmount"
                                @input="value => handleNewPaymentFormInput('amount', value)"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-form-group label="Примечание">
                    <b-form-textarea
                        style="font-size: 0.7777777778em; font-weight: 500;"
                        type="text"
                        class="bg-white bordered"
                        v-model.trim="newPaymentForm.note"
                    />
                </b-form-group>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <b-button @click="validateThenSavePaymentForm" variant="success">Сохранить</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { debounce } from 'lodash'

const newPaymentFormDefault = { amount: 0 }

export default {
    props: {
        actualAgreement: Object,
        mainAgreement: Object,
        chosenAgreement: Object,
        latestEndDate: [Number, String],
        earliestStartDate: [Number, String],
        userSub: String,
        currentMode: String,
        isWriteAccess: Boolean
    },

    data() {
        return {
            newPaymentForm: {...newPaymentFormDefault},
            item243: {},
            kbkOptions: [],
            options243: [],
            headerData: {},
            scheduleData: [],
            totalInflow: 0,
            totalRepayment: 0,
            totalRefund: 0,
            totalCoopayment: 0,
            totalInterest: 0,
            showModal: false,
            selected: 1,
            progress: 100,
            tableFields: [
                {
                    key: "rowNumber",
                    label: "№",
                },
                {
                    key: "leftPrincipal",
                    label: "Остаток основного долга на начало периода",
                },
                {
                    key: "planDatePayment",
                    label: "Дата платежа по графику",
                },
                {
                    key: "planRepaymentSum",
                    label: "Плановая сумма погашения основного долга",
                },
                {
                    key: "datePayment",
                    label: "Дата платежа",
                },
                {
                    key: "code",
                    label: "КБК",
                },
                {
                    key: "factIncomeSum",
                    label: "Фактическая сумма поступления",
                },
                {
                    key: "factRepaymentSum",
                    label: "Фактическая сумма погашения",
                },
                {
                    key: "earlyRepaymentSum",
                    label: "Сумма досрочного погашения",
                },
                {
                    key: "unusedSum",
                    label: "Сумма возврата неиспользованных средств",
                },
                {
                    key: "planInterestRepaymentSum",
                    label: "Плановая сумма выплаты вознаграждения",
                },
                {
                    key: "interestPaymentSum",
                    label: "Сумма выплаты вознаграждения", 
                },
                {
                    key: "forecastCopaymentSum",
                    label: "Сумма сопутствующих платежей (прогноз)",
                },
                {
                    key: "followingPaymentsSum",
                    label: "Сумма сопутствующих платежей",
                },
                {
                    key: "note",
                    label: "Примечание",
                },
                {
                    key: "earlyRepaymentFlag",
                    label: "Досрочное погашение",
                },
                {
                    key: "action",
                    label: "",
                },
            ],
        }
    },

    async created() {
        this.assignHeaderData()
        await this.loadKbkOptions('')
        await this.loadScheduleData()
        this.calculateTotalSums()
        await this.load243Options()
    },

    methods: {
        // #region Api
        async getAgreement(agreementId) {
            const response = await fetch(`/api/budget-loan/${agreementId}`)
            if (!response.ok) {
                this.makeToast('Не удалось загрузить договор (факт)', 'Сообщение', 'danger')
                return
            }
            return await response.json()
        },

        async apiSaveFact(fact) {
            return await fetch('api/budget-loan-schedule/payment-fact-inflow', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(fact)
            })
        },

        async apiEditFact(fact) {
            return await fetch('api/budget-loan-schedule/payment-fact-inflow', {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(fact)
            })
        },

        async apiDeleteFact(id) {
            return await fetch(`api/budget-loan-schedule/payment-fact-inflow/${id}`, {
                method: 'DELETE'
            })
        },

        // TODO: remove
        async apiGenerateFactInterest(agreementId) {
            return await fetch(`api/budget-loan-schedule/payment-fact-interest/generate/${agreementId}`)
        },

        async apiFetchPaymentSchedule(agreementId) {
            const url = "api/budget-loan-schedule/payment-schedule"
            const queries = `/${agreementId}`
            const response = await fetch(url + queries)
            return response
        },

        async apiFetchFactSchedule(mainAgreementId) {
            const url = "api/budget-loan-schedule/payment-fact-inflow"
            const query = `/${mainAgreementId}`
            return await fetch(url + query)
        },

        async apiFetchPaymentInflowSchedule(contractId, firstInflowDateString, frequencyCode) {
            const url =
                "api/budget-loan-schedule/payment-schedule-inflow/generate"
            const queries = `/${contractId}?start_inflow=${firstInflowDateString}&frequency=${frequencyCode}`
            const response = await fetch(url + queries)
            return response
        },

        async apiFetchPrincipalRepaymentSchedule(contractId, firstInflowDateString, frequencyCode) {
            const url =
                "api/budget-loan-schedule/payment-schedule-principal-repayment/generate"
            const queries = `/${contractId}?start_repayment=${firstInflowDateString}&frequency=${frequencyCode}`
            const response = await fetch(url + queries)
            return response
        },

        async apiFetchInterestPaymentSchedule(contractId, frequencyCode) {
            const url =
                "api/budget-loan-schedule/payment-schedule-interest/generate"
            const queries = `/${contractId}?frequency=${frequencyCode}`
            const response = await fetch(url + queries)
            return response
        },
        // #endregion

        // #region Fetches
        async fetchKbkOptions(query, budgetLevelId, begDate, endDate) {
            const queries = `?budget-level-id=${budgetLevelId}&search-text=${query}&beg-date=${begDate}&end-date=${endDate}`
            const response = await fetch('api/dict/dict_ebk_doh/loan-payment' + queries)
            if (!response.ok) {
                this.makeToast('Не удалось подтянуть варинты КБК', 'Ошибка', 'danger')
                return []
            }
            const data = await response.json()
            const options = data.items ? data.items : data

            options.map(item => {
                item.label = `${item.full_code} - ${item.name_ru}` 
            })

            return options
        },
        // #endregion

        // #region Loads
        async loadKbkOptions(query) {
            if (this.actualAgreement?.budgetLevel ?? false) {
                const budgetLevelId = this.actualAgreement?.budgetLevel?.id
                const begDate = this.millisToDate(this.actualAgreement?.dateContract)
                const endDate = this.millisToDate(this.actualAgreement?.contractDateEnd)
                this.kbkOptions = await this.fetchKbkOptions(query, budgetLevelId, begDate, endDate)
            }
        },

        async loadScheduleData() {
            if (!this.actualAgreement.id || !this.mainAgreement.id) {
                return
            }

            this.progress = 10

            let forecastItems = []
            let copaymentForecastItems = []
            let forecastInterestPayments = []
            
            const actualId = this.actualAgreement.id
            const currentDataAgreement = await this.getAgreement(actualId)
            const forecastResponse = await fetch(`/api/budget-loan-schedule/payment-schedule-forecast/get-data/${actualId}`)
            if (forecastResponse.ok) {
                forecastItems = await forecastResponse.json()
                copaymentForecastItems = forecastItems.filter(item => item.sumPenaltyPrincipal ?? item.sumPenaltyInterest)
                const interestForecastItems = forecastItems.filter(it => it.sumInterestForecast)
                if (currentDataAgreement.frequencyRate === '001') {
                    // #region interestItems
                    const initialInterestForecastItems = interestForecastItems.filter(it => it.scheduleDate <= currentDataAgreement.initialInterestRepaymentDate)
                    let initialTotalInterestForecastItem
                    if (initialInterestForecastItems.length > 1) {
                        initialTotalInterestForecastItem = initialInterestForecastItems.reduce((accumulator, currentItem) => {
                            const newSum = accumulator.sumInterestForecast + currentItem.sumInterestForecast
                            const maxScheduleDate = accumulator.scheduleDate < currentItem.scheduleDate ? currentItem.scheduleDate : accumulator.scheduleDate
                            return { ...accumulator, scheduleDate: maxScheduleDate, sumInterestForecast: newSum }
                        })
                    } else if (initialInterestForecastItems.length === 1) {
                        initialTotalInterestForecastItem = initialInterestForecastItems[0]
                    }
                    const followingInterestForecastItems = interestForecastItems.filter(it => {
                        const isFollowing = !Boolean(
                            initialInterestForecastItems.find(initialItem => 
                                it.scheduleDate === initialItem.scheduleDate
                            )
                        )
                        return isFollowing
                    })
                    const totalInterestMap = new Map()
                    followingInterestForecastItems.forEach(item => {
                        const date = new Date(item.scheduleDate)
                        const year = date.getFullYear()
                        const month = date.getMonth() + 1
                        const day = date.getDate()
                        
                        if (month < currentDataAgreement.followingInterestRepaymentMonth) {
                            if (!totalInterestMap.has(year)) {
                                totalInterestMap.set(year, 0)
                            }
                            const accumulatedSum = totalInterestMap.get(year)
                            totalInterestMap.set(year, (accumulatedSum + item.sumInterestForecast))
                        } else if (month > currentDataAgreement.followingInterestRepaymentMonth) {
                            const nextYear = year + 1
                            if (!totalInterestMap.has(nextYear)) {
                                totalInterestMap.set((nextYear), 0)
                            }
                            const accumulatedSum = totalInterestMap.get(nextYear)
                            totalInterestMap.set(nextYear, (accumulatedSum + item.sumInterestForecast))
                        } else if (month === currentDataAgreement.followingInterestRepaymentMonth) {
                            if (day <= currentDataAgreement.followingInterestRepaymentDay) {
                                if (!totalInterestMap.has(year)) {
                                    totalInterestMap.set(year, 0)
                                }
                                const accumulatedSum = totalInterestMap.get(year)
                                totalInterestMap.set(year, (accumulatedSum + item.sumInterestForecast))
                            } else if (day > currentDataAgreement.followingInterestRepaymentDay) {
                                const nextYear = year + 1
                                if (!totalInterestMap.has(nextYear)) {
                                    totalInterestMap.set((nextYear), 0)
                                }
                                const accumulatedSum = totalInterestMap.get(nextYear)
                                totalInterestMap.set(nextYear, (accumulatedSum + item.sumInterestForecast))
                            }
                        }
                    })
                    
                    if (initialTotalInterestForecastItem) {
                        forecastInterestPayments.push({
                            datePayment: currentDataAgreement.initialInterestRepaymentDate,
                            interestSum: initialTotalInterestForecastItem.sumInterestForecast
                        })
                    }
                    totalInterestMap.forEach((totalSum, year) => {
                        const formattedMonth = currentDataAgreement.followingInterestRepaymentMonth.toString().padStart(2, '0');
                        const formattedDay = currentDataAgreement.followingInterestRepaymentDay.toString().padStart(2, '0');
                        const interestRepaymentDateMilliseconds = (new Date(`${year}-${formattedMonth}-${formattedDay}`)).getTime()
                        forecastInterestPayments.push({ datePayment: interestRepaymentDateMilliseconds, interestSum: totalSum })
                    })
                    // #endregion
                } else {
                    // TODO: Account dates instead of relying on empty values,
                    // since it is possible to have both repayment and interest dates to be the same
                    const onlyInterestRows = interestForecastItems.filter(it => (
                        !it.sumInflowForecast 
                        && !it.sumPrincipalRepaymentForecast
                        && !it.sumRefund
                        && !it.sumRepaymentUnused
                    ))
                    forecastInterestPayments = onlyInterestRows.map(item => {
                        return {
                            datePayment: item.scheduleDate,
                            interestSum: item.sumInterestForecast
                        }
                    })
                }
            }

            // Plan/forecast repayment
            const forecastRepaymentResponse = await this.fetchForecastRepayment(actualId)
            const forecastRepayments = await forecastRepaymentResponse.json()
            let planRepaymentItems
            if (forecastRepayments.length > 0) {
                planRepaymentItems = forecastRepayments
                .filter(item => item.sumPrincipalRepaymentForecast)
                .map(item => {
                    return {
                        planDatePayment: item.scheduleDate,
                        datePayment: item.scheduleDate,
                        planRepaymentSum: item.sumPrincipalRepaymentForecast,
                        typeSchedule: 2,
                        leftPrincipal: item.principalBalance,
                        isPlan: true
                    }
                })
            } else {
                planRepaymentItems = (await this.fetchRepaymentPlan(actualId))
                .filter(item => item.typeSchedule === 2)
                .map(item => {
                    return {
                        id: item.id,
                        planDatePayment: item.datePayment,
                        datePayment: item.datePayment,
                        planRepaymentSum: item.sumPaymentPrincipal,
                        typeSchedule: item.typeSchedule,
                        leftPrincipal: item.leftPrincipal,
                        isPlan: true
                    }
                })
            }

            this.progress = 30

            // Fact
            const factResponse = await this.apiFetchFactSchedule(this.mainAgreement.id)
            if (!factResponse.ok) {
                this.makeToast("Не удалось подтянуть фактические данные", "Ошибка", "danger")
                return 
            }
            const factItems = (await factResponse.json())
                .map(item => {
                    const typeKey = this.mapTypeNumberToTypeField(item.typeSchedule)
                    return {
                        id: item.id,
                        [typeKey]: item.sumPaymentFact,
                        datePayment: item.dateFact,
                        code: item.code,
                        note: item.note,
                        typeSchedule: item.typeSchedule,
                        isFact: true
                    }
                })

            this.progress = 50

            // combining all graphs
            let combinedItems = factItems

            combinedItems = combinedItems.sort((a, b) => a.datePayment - b.datePayment)

            combinedItems = combinedItems
                .map((item, index) => {
                    if (!item.factIncomeSum) {
                        item.factIncomeSum = 0
                    }
                    if (!item.factRepaymentSum) {
                        item.factRepaymentSum = 0
                    }
                    if (!item.earlyRepaymentSum) {
                        item.earlyRepaymentSum = 0
                    }
                    if (!item.unusedSum) {
                        item.unusedSum = 0
                    }
                    if (!item.followingPaymentsSum) {
                        item.followingPaymentsSum = 0
                    }

                    // подсчет кол-во дней между началом и концом периодов
                    if (item.dateBeg && item.dateEnd) {
                        const start = (new Date(item.dateBeg)).getTime()
                        const end = (new Date(item.dateEnd)).getTime()
                        const timeDifference = end - start
                        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1
                        item.daysCount = daysDifference > 360 ? 360 : daysDifference
                    }

                    // подсчет "Остаток основного долга на начало периода"
                    if (index === 0) {
                        item.leftPrincipal = 0
                    } else {
                        const previousItem = combinedItems[index-1]
                            
                        const amount = previousItem.factIncomeSum
                            - previousItem.factRepaymentSum
                            - previousItem.earlyRepaymentSum
                            - previousItem.unusedSum
                            
                        item.leftPrincipal = previousItem.leftPrincipal + amount  
                    }

                    if (item.code && item.code.substring(0, 4) === '5011') {
                        if (item.typeSchedule !== 2) {
                            item.earlyRepaymentFlag = true
                        } else {
                            item.earlyRepaymentFlag = false
                        }
                        } else {
                            item.earlyRepaymentFlag = null
                        }
                        
                        return item
                })

            // insert plan repayments
            let nonSameDatePlanRepaymentItems = JSON.parse(JSON.stringify(planRepaymentItems))
            planRepaymentItems.forEach(planItem => {
                const sameDateItems = combinedItems.filter(combinedItem => planItem.datePayment === combinedItem.datePayment)
                if (sameDateItems && sameDateItems.length > 0) {
                    let row
                    const anyRow = sameDateItems[0]
                    const preferredRow = sameDateItems.find(it => it.factRepaymentSum)
                    if (preferredRow) {
                        row = preferredRow
                    } else {
                        row = anyRow
                    }

                    row.planDatePayment = planItem.datePayment
                    row.planRepaymentSum = planItem.planRepaymentSum
                    nonSameDatePlanRepaymentItems = nonSameDatePlanRepaymentItems.filter(it => {
                        return it.datePayment !== planItem.datePayment
                    })
                }
            })
            combinedItems = combinedItems.concat(nonSameDatePlanRepaymentItems)

            // insert copayments from forecast
            const copiedCombinedItems2 = JSON.parse(JSON.stringify(combinedItems))
            let nonSameCopaymentItems = JSON.parse(JSON.stringify(copaymentForecastItems))
            copaymentForecastItems.forEach(copaymentItem => {
                for (let index = 0; index < copiedCombinedItems2.length; index++) {
                    const combinedItem = copiedCombinedItems2[index]
                    const copaymentDate = this.millisToDate(copaymentItem.scheduleDate)
                    const combinedDate = this.millisToDate(combinedItem.datePayment)
                    if (copaymentDate === combinedDate && combinedItem.followingPaymentsSum) {
                        const copayment = (copaymentItem.sumPenaltyInterest??0) + (copaymentItem.sumPenaltyPrincipal??0)
                        combinedItems[index].forecastCopaymentSum = copayment
                        nonSameCopaymentItems = nonSameCopaymentItems.filter(it => {
                            return it.scheduleDate !== copaymentItem.scheduleDate
                        })
                        break
                    }
                }
            })
            combinedItems = combinedItems.concat(nonSameCopaymentItems.map(it => { 
                const copayment = (it.sumPenaltyInterest??0) + (it.sumPenaltyPrincipal??0)
                return { datePayment: it.scheduleDate, forecastCopaymentSum: copayment }
            }))

            // При плановом и фактовом закрытий суммы кредита, последнее плановое 
            // вознаграждение садить на послендюю плановую выплату            
            const planRepaymentTotal = planRepaymentItems
                .map(it => it.planRepaymentSum)
                .reduce((sum, num) => sum + num, 0)
            const factRepaymentTotal = factItems
                .map(it => it.factRepaymentSum + it.earlyRepaymentSum + it.unusedSum)
                .reduce((sum, num) => sum + num, 0)
                
            if ((planRepaymentTotal + factRepaymentTotal) >= this.actualAgreement.amount) {
                const sortedRepayments = planRepaymentItems.sort((a, b) => a.planDatePayment - b.planDatePayment)
                const lastRepayment = sortedRepayments.at(-1)
                if (forecastInterestPayments && forecastInterestPayments.length > 0) {
                    const sortedInterest = forecastInterestPayments.sort((a, b) => a.datePayment - b.datePayment)
                    const lastInterest = sortedInterest.at(-1)
                    forecastInterestPayments.forEach(it => {
                        if (it.datePayment === lastInterest.datePayment) {
                            it.datePayment = lastRepayment.planDatePayment
                        }
                    })
                }
            }

            // insert forecast interest
            const copiedCombinedItems3 = JSON.parse(JSON.stringify(combinedItems))
            let nonSameDateInterestItems = JSON.parse(JSON.stringify(forecastInterestPayments))
            forecastInterestPayments.forEach(interestItem => {
                for (let index = 0; index < copiedCombinedItems3.length; index++) {
                    const combinedItem = copiedCombinedItems3[index]
                    const interestDate = this.millisToDate(interestItem.datePayment)
                    const combinedDate = this.millisToDate(combinedItem.datePayment)
                    if (interestDate === combinedDate && combinedItem.interestPaymentSum) {
                        combinedItems[index].planInterestRepaymentSum = interestItem.interestSum
                        nonSameDateInterestItems = nonSameDateInterestItems.filter(it => {
                            return it.datePayment !== interestItem.datePayment
                        })
                        break
                    }
                }
            })
            combinedItems = combinedItems.concat(nonSameDateInterestItems.map(it => { 
                return { datePayment: it.datePayment, planInterestRepaymentSum: it.interestSum }
            }))

            // sorting
            combinedItems = combinedItems.sort((a, b) => { 
                const dateA = a.datePayment ? a.datePayment : a.planDatePayment
                const dateB = b.datePayment ? b.datePayment : b.planDatePayment
                return dateA - dateB
            })

            // Coloring plan/forcast
            combinedItems = combinedItems.map(item => {
                if (item.planDatePayment) {
                    item._cellVariants = { planDatePayment: 'primary', planRepaymentSum: 'primary' }
                    if (!item.isFact) {
                        item._rowVariant = 'primary'
                        delete item.datePayment
                    }
                }
                if (item.forecastCopaymentSum) {
                    item._cellVariants = { forecastCopaymentSum: 'primary' }
                    if (!item.isFact) {
                        item._rowVariant = 'primary'
                    }
                }
                if (item.planInterestRepaymentSum) {
                    item._cellVariants = { planInterestRepaymentSum: 'primary' }
                    if (!item.isFact) {
                        item._rowVariant = 'primary'
                    }
                }
                return item
            })

            this.scheduleData = combinedItems
            this.progress = 100
        },

        async load243Options() {
            if (!this.mainAgreement.id) {
                return
            }
            const response = await fetch(`api/budget-loan-schedule/payment-fact-inflow/get-243-data-v02/${this.mainAgreement.id}`)
            if (response.ok) {
                const data = await response.json()
                data.forEach(item => {
                    const formattedDate = this.millisToDate(item.paymentdate)
                    item.label = item.description
                })
                this.options243 = data
            }
        },

        async fetchRepaymentPlan(agreementId) {
            const response = await this.apiFetchPaymentSchedule(agreementId)
            if (!response.ok) {
                this.makeToast("Не удалось подтянуть график", "Ошибка", "danger")
                return
            }
            const unsortedScheduleData = await response.json()

            // (1/3) fetching inflow
            const inflowItems = unsortedScheduleData.filter(item => item.typeSchedule === 1)

            // (2/3) fetching principal repayment
            const principalItems = unsortedScheduleData.filter(item => item.typeSchedule === 2)
            principalItems.map(item => {
                item.sumPaymentPrincipal = item.sumPayment
                item.sumPayment = 0
            })

            // combining all graphs
            let combinedItems = inflowItems
            const toAddPrincipalItems = []
            for (let i = 0; i < principalItems.length; i++) {
                const principalItem = principalItems[i]

                const sameDateItem = combinedItems.find(inflowItem => inflowItem.datePayment === principalItem.datePayment)
                if (sameDateItem) {
                    sameDateItem.sumPaymentPrincipal = principalItem.sumPaymentPrincipal
                    sameDateItem.typeSchedule = 2
                } else {
                    toAddPrincipalItems.push(principalItem)
                }
            }
            combinedItems = combinedItems.concat(toAddPrincipalItems)

            combinedItems = combinedItems.sort(
                (a, b) => a.datePayment - b.datePayment
            )

            combinedItems.map((item, index) => {
                // set zeros in upsent payments
                if (!item.sumPayment) {
                    item.sumPayment = 0
                }
                if (!item.sumPaymentPrincipal) {
                    item.sumPaymentPrincipal = 0
                }

                // calculate "Остаток основного долга на начало периода"
                if (index === 0) {
                    item.leftPrincipal = 0
                } else {
                    const previousItem = combinedItems[index-1]
                    const amount = previousItem.sumPayment - previousItem.sumPaymentPrincipal
                    item.leftPrincipal = previousItem.leftPrincipal + amount  
                }
            })

            return combinedItems.filter(item => item.sumPaymentPrincipal)
        },

        async fetchForecastRepayment(agreementId) {
            return await fetch(`/api/budget-loan-schedule/payment-schedule-forecast/get-data/${agreementId}`)
        },
        // #endregion

        // #region Handlers
        onModalModeChange() {
            this.newPaymentForm = {...newPaymentFormDefault}
            this.item243 = {}
        },

        on243Select(value) {
            const kbk = this.kbkOptions.find(item => item.full_code == value.specific)
            if (kbk) {
                this.newPaymentForm.date = this.millisToDate(value.paymentdate)
                this.newPaymentForm.amount = value.amount
                this.newPaymentForm.note = value.description
                this.newPaymentForm.kbk = kbk
                this.onKbkSelect(kbk)
            } else {
                this.makeToast('Данный платеж не являеется доступным для данного договора', 'Сообщение', 'danger')
            }
        },

        onKbkSelect(value) {
            let type
            if ((value.kat === 7 && value.cls === 1 && value.pcl === 2)) {
                type = 1
            } else if ((value.kat === 5 && value.cls === 1 && value.pcl === 1)) {
                type = 2
            } else if ((value.kat === 2 && value.cls === 1 && value.pcl === 7)) {
                type = 3
            } else if ((value.kat === 5 && value.cls === 1 && value.pcl === 2)) {
                type = 8
            } else if ((value.kat === 2 && value.cls === 4 && value.pcl === 1)) {
                type = 5
            }
            this.newPaymentForm = {...this.newPaymentForm, paymentType: type}
        },

        handleLoadKbkOptions: debounce(async function (query) {
            await this.loadKbkOptions(query)
        }, 300),

        toggleFormModal(isOn = null) {
            if (isOn === null) {
                this.showModal = !this.showModal
            } else {
                this.showModal = isOn
            }
        },

        handleDateFormat(value) {
            let v = value
            if (value.indexOf('-') >= 5) {
                const year = value.slice(0, 4)
                const rest = value.slice(-5)
                v = year + '-' + rest
            }
            return v
        },

        handleNewPaymentFormInput(key, value) {
            switch (key) {
                case 'date': {
                    if (!value.startsWith('0')) {
                        this.newPaymentForm = { ...this.newPaymentForm, date: value }
                    } // if allow year to start with 0, would reset input
                    break
                }
                case 'amount': {
                    this.newPaymentForm = {...this.newPaymentForm, amount: value}
                    break
                }
                default:
            }
        },

        handleInputDate(key, value) {
            if (!value.startsWith('0')) {
                this.newPaymentForm = { ...this.newPaymentForm, date: value }
            } // if allow year to start with 0, would reset input
        },
        // #endregion

        // #region Utils
        calculateTotalPlanInterest() {
            let total = 0
            this.scheduleData.forEach((item) => {
                if (item.planInterestRepaymentSum) {
                    total += item.planInterestRepaymentSum
                }
            })
            return total
        },

        calculateTotalForecastCopayment() {
            let total = 0
            this.scheduleData.forEach((item) => {
                if (item.forecastCopaymentSum) {
                    total += item.forecastCopaymentSum
                }
            })
            return total
        },

        calculateTotalUnusedSum() {
            let total = 0
            this.scheduleData.forEach((item) => {
                if (item.unusedSum) {
                    total += item.unusedSum
                }
            })
            return total
        },

        copyData() {
            const text = `Платеж по договору ${this.chosenAgreement.numberContract} от ${this.chosenAgreement.dateContract} по проекту ${this.chosenAgreement.codeProject?.nameRu}`
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text)
                    .then(() => {
                        this.makeToast('Скопировано', 'Сообщение', 'success')
                    })
                    .catch(err => {
                        this.makeToast('Не удалось скопировать текст', 'Ошибка', 'danger')
                    })
            } else {
                const textArea = document.createElement('textarea')
                textArea.value = text
                document.body.appendChild(textArea)
                textArea.select()

                try {
                    const successful = document.execCommand('copy')
                    if (successful) {
                        this.makeToast('Скопировано', 'Сообщение', 'success')
                    } else {
                        throw new Error()
                    }
                } catch (err) {
                    this.makeToast('Не удалось скопировать текст', 'Ошибка', 'danger')
                } finally {
                    document.body.removeChild(textArea)
                }
            }
        },

        // millisToDate(millis) {
        //     if (!millis) {
        //         return ""
        //     }
        //     const date = new Date(millis)
        //     const year = date.getFullYear()
        //     let month = date.getMonth() + 1
        //     if (month.toString().length === 1) {
        //         month = "0" + month
        //     }
        //     let day = date.getDate()
        //     if (day.toString().length === 1) {
        //         day = "0" + day
        //     }
        //     return year + "-" + month + "-" + day
        // },
        millisToDate(millis) {
            if (!millis) {
                return '';
            }
            const date = new Date(millis);

            // Check if time is 23:00:00 and add an hour if it is
            if (date.getHours() === 23 && date.getMinutes() === 0 && date.getSeconds() === 0) {
                date.setTime(date.getTime() + 3600000); // Adds 1 hour (3600000 milliseconds)
            }

            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month.toString();
            
            let day = date.getDate();
            day = day < 10 ? '0' + day : day.toString();

            return year + '-' + month + '-' + day;
        },

        millisToYear(millis) {
            if (!millis) {
                return ""
            }
            const date = new Date(millis)
            const year = date.getFullYear()

            return year
        },

        formatOnlyNumeric(value) {
            value += ''
            value = value.replace(/[^\d,. -]/g, "")
            return value
        },

        formatDigitReadability(number) {
            number += ""
            const removedSpaceString = number.replace(/\s/g, "")
            number = removedSpaceString.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            return number
        },

        formatMaxFloat(value) {
            const maximumFloatLength = 2
            value += ''
            value = value.replace(/[^\d,. -]/g, "")
            let until = value.length
            if (value.indexOf(',') !== -1) {
                until = value.indexOf(',') + maximumFloatLength + 1
            } else if (value.indexOf('.') !== -1) {
                until = value.indexOf('.') + maximumFloatLength + 1
            }
            value = value.substring(0, until)
            return value
        },

        rounding(value) {
            value += ''
            value = value.replace(/[^\d,. -]/g, "")
            let thirdIndex
            if (value.indexOf(',') !== -1) {
                thirdIndex = value.indexOf(',') + 3
            } else if (value.indexOf('.') !== -1) {
                thirdIndex = value.indexOf('.') + 3
            }
            if (thirdIndex) {
                let thirdNumber = parseInt(value[thirdIndex])
                while (thirdNumber >= 5 && thirdNumber <= 9) {
                    value = parseFloat(value) + 0.001
                    thirdNumber = parseInt(value[thirdIndex])
                }
            }

            return value
        },

        formatAmount(value) {
            value += ''
            value = this.formatMaxFloat(value)
            value = this.formatDigitReadability(value)
            return value
        },

        makeToast(message, title, variant) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true,
            })
        },

        deepCopy(object) {
            return JSON.parse(JSON.stringify(object))
        },
        // #endregion

        openAdditionalsModal(action = null) {
            this.$emit('openAdditionalsModal', action)
        },

        assignHeaderData() {
            if (this.actualAgreement?.id === this.mainAgreement?.id) {
                this.headerData = {
                    mainNumberContract: this.mainAgreement?.numberContract,
                    actualNumberContract: null,
                    actualAmount: this.mainAgreement?.amount,
                    actualContractor: this.mainAgreement?.contractor?.nameRu
                }
            } else {
                this.headerData = {
                    mainNumberContract: this.actualAgreement?.numberContractMain,
                    actualNumberContract: this.actualAgreement?.numberContract,
                    actualAmount: this.actualAgreement?.amount,
                    actualContractor: this.actualAgreement?.contractor?.nameRu
                }
            }
        },

        async validateThenSavePaymentForm() {
            if (this.validatePaymentForm()) {
                await this.saveOrUpdatePaymentForm()
            }
        },

        validatePaymentForm() {
            // Платежи должны быть в рамках действия договора
            const latest = (new Date(this.latestEndDate)).getTime()
            const earliest = (new Date(this.earliestStartDate)).getTime()
            const checked = (new Date(this.newPaymentForm.date)).getTime()
            if (checked < earliest) {
                this.makeToast('Дата платежа должна быть позже даты подписания', 'Ошибка', 'danger')
                return false
            } else if (checked > latest) {
                this.makeToast('Дата платежа должна быть раньше даты возврата', 'Ошибка', 'danger')
                return false
            }

            // Первый платеж должен быть поступлением
            if (this.newPaymentForm.paymentType !== 1) {
                const firstFound = this.scheduleData.find(item => item.isFact && item.typeSchedule === 1)
                if (!firstFound) {
                    this.makeToast('Необходимо ввести фактическую сумму поступлений', 'Ошибка', 'danger')                    
                    return false
                }

                const firstIncomeDate = (new Date(firstFound.datePayment)).getTime()
                if (firstIncomeDate > checked) {
                    this.makeToast('Погашение не может быть раньше Поступлений', 'Ошибка', 'danger')                    
                    return false
                }
            }

            return true
        },

        async saveOrUpdatePaymentForm() {
            const toSave = this.transformPaymentForSave(this.newPaymentForm, this.mainAgreement.id)

            let response
            if (toSave.id ?? false) {
                response = await this.apiEditFact(toSave)
            } else {
                response = await this.apiSaveFact(toSave)
            }
            
            if (!response.ok) {
                let text = 'Извините, произошла ошибка на сервере или некорректно заполнены поля.'
                const errorResponse = await response.json()
                const message = errorResponse?.args[0]?.value[0]
                if (message) {
                    text = message
                }
                this.makeToast(text, 'Ошибка сохранения', 'danger')
                return
            }

            this.makeToast('Сохранено', 'Сообщение', 'success')
            await this.resetFactTable()
        },

        async resetFactTable() {
            this.toggleFormModal(false)
            delete this.newPaymentForm.kbk
            this.newPaymentForm = {...newPaymentFormDefault}
            await this.loadKbkOptions('')
            await this.loadScheduleData()
            this.calculateTotalSums()
            this.$emit('reloadForecast')
        },

        transformPaymentForSave(payment, mainId) {
            const amountFloat = typeof payment.amount === 'string'
            ? parseFloat(payment.amount.replace(/\s/g, '').replace(/\,/g, '.'))
            : payment.amount
            return {
                id: payment.id,
                idContract: mainId,
                typeSchedule: payment.paymentType,
                sumPaymentFact: amountFloat,
                dateFact: payment.date,
                code: payment.kbk.full_code,
                note: payment.note,
                userName: this.userSub
            }
        },

        async confirmDeletion(id) {
            this.$bvModal.msgBoxConfirm(
                'Удалить выбранный факт?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(confirmed => {
                    if (confirmed) {
                        this.removeRow(id)
                    }
                })
                .catch(error => {
                    this.makeToast('Не удалось удалить факт', 'Ошибка удаления', 'danger')
                    console.error(error.toString())
                })
        },

        async confirmEarlyRepaymentFlagToggle(item, prevValue) {
            if (!this.isWriteAccess) {
                return
            }

            const title = item.typeSchedule === 2 ? 'Конвертировать в досрочное погашение?' : 'Обратить изменение?'
            const okTitle = item.typeSchedule === 2 ? 'конвертировать' : 'обратить'
            this.$bvModal.msgBoxConfirm(
                title,
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: okTitle,
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(confirmed => {
                    if (confirmed) {
                        this.toggleEarlyRepaymentFlag({...item})
                    }
                })
                .catch(error => {
                    this.makeToast('Не удалось', 'Ошибка', 'danger')
                    console.error(error.toString())
                })
        },

        async toggleEarlyRepaymentFlag(transaction) {
            const amount = transaction.typeSchedule === 2 ? transaction.factRepaymentSum : transaction.earlyRepaymentSum
            const newType = transaction.typeSchedule === 2 ? 4 : 2
            this.newPaymentForm = {
                id: transaction.id,
                paymentType: newType,
                amount: amount,
                date: transaction.datePayment,
                kbk: { full_code: transaction.code },
                note: transaction.note,
            }
            await this.saveOrUpdatePaymentForm()
            delete this.newPaymentForm.kbk
            this.newPaymentForm = {...newPaymentFormDefault}
        },

        async removeRow(id) {
            const response = await this.apiDeleteFact(id)
            if (!response.ok) {
                let text = 'Извините, произошла ошибка на сервере.'
                const errorResponse = await response.json()
                const message = errorResponse?.args[0]?.value[0]
                if (message) {
                    text = message
                }
                this.makeToast(text, 'Ошибка удаления', 'danger')
                return
            }
            this.makeToast('Успешно удалено', 'Сообщение', 'success')
            await this.resetFactTable()
        },

        openSaveModal() {
            this.newPaymentForm = {...newPaymentFormDefault}
            this.item243 = {}
            this.toggleFormModal(true)
        },

        openEditModal(data) {
            this.newPaymentForm = {...newPaymentFormDefault}
            this.toggleFormModal(true)

            const kbkCode = this.kbkOptions.find(item => item.full_code === data.code)
            const amountFieldKey = this.mapTypeNumberToTypeField(data.typeSchedule)

            this.newPaymentForm.id = data.id
            this.newPaymentForm.kbk = kbkCode
            this.newPaymentForm.paymentType = data.typeSchedule
            this.newPaymentForm.date = data.datePayment
            this.newPaymentForm.amount = data[amountFieldKey]
            this.newPaymentForm.note = data.note
        },

        mapTypeNumberToTypeField(scheduleTypeNumber) {
            switch (scheduleTypeNumber) {
                case 1:
                    return 'factIncomeSum'
                case 2:
                    return 'factRepaymentSum'
                case 3:
                    return 'interestPaymentSum'
                case 4:
                    return 'earlyRepaymentSum'
                case 5:
                    return 'followingPaymentsSum'
                case 8:
                    return 'unusedSum'
                default:
                    return ''
            }
        },

        displayTypeName(typeNumber) {
            switch (typeNumber) {
                case 1:
                    return 'Поступления кредитов из вышестоящего бюджета'
                case 2:
                    return 'Погашения бюджетных кредитов'
                case 3:
                    return 'Выплаты по вознаграждениям'
                case 4:
                    return 'Возврат бюджетных кредитов'
                case 5:
                    return 'Сопутствующие платежи (штраф, пеня)'
                default:
                    return ''
            }
        },

        calculateTotalSums() {
            let tempInflow = 0
            let tempRepayment = 0
            let tempRefund = 0
            let tempCoopayment = 0
            let tempInterest = 0
            this.scheduleData.forEach((item) => {
                if (item.factIncomeSum) {
                    tempInflow += item.factIncomeSum
                }
                if (item.factRepaymentSum) {
                    tempRepayment += item.factRepaymentSum
                }
                if (item.earlyRepaymentSum) {
                    tempRefund += item.earlyRepaymentSum
                }
                if (item.followingPaymentsSum) {
                    tempCoopayment += item.followingPaymentsSum
                }
                if (item.interestPaymentSum) {
                    tempInterest += item.interestPaymentSum
                }
            })
            this.totalInflow = tempInflow
            this.totalRepayment = tempRepayment
            this.totalRefund = tempRefund
            this.totalCoopayment = tempCoopayment
            this.totalInterest = tempInterest
        },

        goBack() {
            this.$router.back()
        }
    },
}
</script>

<style lang="scss" scoped>
    .modal-text{
        font-size: 14px;
        color: #1C2A3E;
        text-align: center;
    }
    .modal-radio-fact-graphic{
        text-transform: uppercase;
    }
    .modal-fact-graphic-content{
        background-color: #F7F9FC;
        padding: 10px;
    }

    .sticky-bottom-row {
        position: sticky;
        z-index: 100;
        background-color: #F7F9FC;
        bottom: 0;
    }

    .bordered{
       border: 1px solid #BCD2E0;
    }

    .no-wrap-space {
        white-space: nowrap;
    }

.more-info {
    position: relative;

    input {
        padding: 0 10px 0 25px;
        color: red;
    }

    .pop-up {
        position: absolute;
        top: 35px;
        width: 240px;
        padding: 15px;
        background-color: #FFF9EB;
        border-radius: 5px;
        box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.07);
        //opacity: 0;
        z-index: 1010;
        //visibility: hidden;
        transition: 0.2s all ease;

        &:before {
            content: '';
            position: absolute;
            top: -8px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent #FFF9EB transparent;
        }

        p {
            font-size: 12px;
            font-weight: 600;
        }

        .red-text {
            color: red;
        }

        table {
            border: none;
            width: 100%;

            tr {
                td {
                    padding: 10px;
                    border-left: none !important;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);

                    &:last-child {
                        border-right: none !important;
                    }
                }

                &:last-child {
                    td {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }

    .pop-up-reverse {
        position: absolute;
        top: 35px;
        right: -1000px;
        width: 240px;
        padding: 15px;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.07);
        opacity: 0;
        z-index: 1010;
        visibility: hidden;
        transition: 0.2s all ease;

        &:before {
            content: '';
            position: absolute;
            top: -8px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent #FFF9EB transparent;
        }

        .red-text {
            color: red;
        }

        table {
            border: none;
            width: 100%;

            tr {
                td {
                    padding: 10px;
                    border-left: none !important;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);

                    &:last-child {
                        border-right: none !important;
                    }
                }

                &:last-child {
                    td {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }

    .hold-on-hover {
        &:hover {
            opacity: 1;
            visibility: visible;
        }
    }

    .visible {
        opacity: 1;
        visibility: visible;
    }

    i {
        position: absolute;
        top: 6px;
        left: 5px;
        color: red;

        &:hover {
            &+.pop-up {
                opacity: 1;
                visibility: visible;
            }

            &+.pop-up-reverse {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .show-on-hover {
        &:hover {
            &+.pop-up {
                opacity: 1;
                visibility: visible;
            }

            &+.pop-up-reverse {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.text-only {
        &>span {
            color: #212529;
            text-decoration: underline;
        }

        &:hover {
            .pop-up {
                opacity: 1;
                visibility: visible;
            }

            .pop-up-reverse {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &:not(.more-info-right) {
        .pop-up {
            left: 0;

            &:before {
                left: 5px;
            }
        }

        .pop-up-reverse {
            left: 0;

            &:before {
                left: 5px;
            }
        }
    }

    &.more-info-right {
        .pop-up {
            right: 0;

            &:before {
                right: 5px;
            }
        }

        .pop-up-reverse {
            right: 0;

            &:before {
                right: 5px;
            }
        }
    }
}
</style>
